<template>
  <div id="app">
    <ssLayout>
      <RouterView />
    </ssLayout>
  </div>
</template>
<script>
import ssLayout from './pages/ssLayout.vue'
export default {
  name: 'App',
  components: {
    ssLayout
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  background-color: #e0e0e0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
