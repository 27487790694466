<!-- src/components/vsFooter.vue -->
<template>
  <footer>
    <RouterLink to="/terms">Terms and Conditions</RouterLink>
    <p>&copy; 2024 Ramonie. All rights reserved. </p>
  </footer>
</template>
<style scoped>
a {
  color: #fff;
  text-decoration: none;
}
footer {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
  background-color: #2f4f4f;
  margin-top: 22px;
  color: white;
}
</style>
<script>
export default {
  name: 'ssFooter',
};
</script>
