<template>
	<div class="container">
		<slot></slot>
	</div>
</template>

<style scoped>
.container {
	width: 1200px;
	max-width: 80%;
	margin: 0 auto;
	padding: 20px;
	width: 100%;
	border-radius: 8px;
	box-shadow: 0px 4px 8px 0px #000;
	flex: 1;
}
@media screen and (max-width: 768px) {
	.container {
		max-width: 90%;
		padding: 4px;
	}
}
</style>

<script>
export default {
	name: "ssContainer",
};
</script>
