<template>
  <div class="info-section">
    <h2>How It Works</h2>
    <ol>
      <li>Pay $5 to take the spotlight and display your image on the main page for 10 minutes minimum.</li>
      <li>Your image will be seen by all visitors to the site during this time.</li>
      <li>When your time is up, another user can pay to take your place.</li>
      <li>Keep an eye on the site to reclaim your spot and stay in the limelight!</li>
    </ol>
    <h2>Benefits</h2>
    <ol>
      <li>Instant visibility and recognition on a growing platform.</li>
      <li>Engage with a dynamic and interactive community.</li>
      <li>Simple and straightforward way to promote yourself or your brand.</li>
      <li>Affordable and fun way to gain exposure.</li>
    </ol>
    <h2>Rules</h2>
    <ol>
      <li>All images must adhere to our community guidelines and terms of service.</li>
      <li>No offensive or inappropriate content is allowed.</li>
      <li>Users must own the rights to the images they upload.</li>
      <li>Any misuse or violation of rules will result in immediate removal and possible ban.</li>
      <li>Payments are non-refundable.</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "ssInfo",
};
</script>

<style scoped>
.info-section {
  max-width: 600px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

ol {
  padding-left: 20px;
  margin-bottom: 15px;
}

li {
  margin-bottom: 8px;
  line-height: 1.4;
  color: #555;
}

ol li::marker {
  font-weight: bold;
  color: #007BFF;
}
</style>
