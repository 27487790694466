import { createWebHistory, createRouter } from 'vue-router'

import ssHome from './pages/ssHome.vue'
import ssTerms from './pages/ssTerms.vue'

const routes = [
  { path: '/', component: ssHome },
  { path: '/terms', component: ssTerms },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})
export default router