<template>
  <ssContainer style="margin-top: 30px;">
  <div class="terms-of-service">
    <header>
      <h1>Terms of Service</h1>
    </header>
    <section>
      <h2>1. Introduction</h2>
      <p>Welcome to Spot Stealer. By accessing or using our website, you agree to comply with and be bound by these Terms of Service. Please read them carefully.</p>
    </section>
    <section>
      <h2>2. Eligibility</h2>
      <p>You must be at least 18 years old to use our services. By using our website, you represent and warrant that you meet this age requirement.</p>
    </section>
    <section>
      <h2>3. User Accounts</h2>
      <p>To access certain features of our website, you may be required to create an account. You agree to provide accurate and complete information when creating your account and to keep this information up-to-date.</p>
    </section>
    <section>
      <h2>4. User Content</h2>
      <p>You are responsible for any content you upload or submit to our website. By uploading content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display your content in connection with our services.</p>
    </section>
    <section>
      <h2>5. Payments and Refunds</h2>
      <p>All payments made on Spot Stealer are final and non-refundable. We reserve the right to change our fees at any time.</p>
    </section>
    <section>
      <h2>6. Prohibited Activities</h2>
      <p>You agree not to engage in any activity that is illegal, harmful, or interferes with the operation of our website. This includes, but is not limited to, hacking, spamming, and distributing malware.</p>
    </section>
    <section>
      <h2>7. Termination</h2>
      <p>We reserve the right to terminate or suspend your account at any time, with or without cause, and with or without notice.</p>
    </section>
    <section>
      <h2>8. Limitation of Liability</h2>
      <p>We are not liable for any damages arising from your use of our website. This includes, but is not limited to, direct, indirect, incidental, punitive, and consequential damages.</p>
    </section>
    <section>
      <h2>9. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms of Service at any time. We will notify you of any changes by posting the new terms on our website. Your continued use of the website after such changes constitutes your acceptance of the new terms.</p>
    </section>
  </div>
  </ssContainer>
</template>
<script>
import ssContainer from '../components/ssContainer.vue';
export default {
  name: "ssTerms",
  components: {
    ssContainer
  }
};
</script>
<style scoped>
.terms-of-service {
  padding: 20px;
}
header {
  margin-bottom: 20px;
}
section {
  margin-bottom: 20px;
}
h2 {
  margin-top: 20px;
}
p {
  margin: 10px 0;
}
</style>
