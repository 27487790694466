<template>
	<div class="paymodal">
		<h2>Take the Spot --- YEET!</h2>
		<!-- Loading Spinner -->
		<div class="spinner-container" v-if="!stripeLoaded">
			<div class="spinner"></div>
		</div>
		<!-- Payment Form -->
		<div v-if="!paymentSent">
			<div style="width: 100%; display: flex; justify-content: flex-end;">
				<button style="margin-bottom: 12px; padding: 4px 8px;" @click="cancelPayment">Cancel</button>
			</div>
			<form class="payment-form" id="payment-form" @submit.prevent="pay">
				<div id="payment-element">
					<!-- Elements will create form elements here -->
				</div>
				<button style="margin: 20px auto" id="submit">Pay Now</button>
				<div id="error-message">
					<!-- Display error message to your customers here -->
				</div>
			</form>
		</div>
		<!-- Waiting for Payment Confirmation -->
		<div v-if="paymentSent && !paymentConfirmed && !paymentFailed">
			<p>Confirming Payment...</p>
		</div>
		<!-- Payment Failed -->
		<div v-if="paymentFailed">
			<p>Payment Failed. Please try again.</p>
			<button @click="cancelPayment">Close</button>
		</div>
		<!-- Payment Confirmed, now time for the YEET! -->
		<div v-if="paymentConfirmed && !spotTaken">
			<form @submit.prevent="submitForm">
				<div class="form-group">
					<label for="photo">Upload Your Photo:</label>
					<input type="file" id="photo" @change="handleFileUpload" required />
				</div>
				<div class="form-group">
					<label for="text">Enter Your Text:</label>
					<input type="text" id="text" v-model="text" required />
				</div>
				<div v-if="takeError" class="take-error"><strong>Error:</strong> {{ takeError }}</div>
				<button type="submit">Take Spot Now!</button>
			</form>
		</div>
		<div v-if="spotTaken">
			<p>Spot Taken!</p>
			<button @click="releaseTheSpot">Close</button>
		</div>
	</div>
</template>
<script>
import { loadScript } from "../utils/loadstripe";
export default {
	// pk_test_51PBkjy2MsisEK4FeDPfcDUbfPAyRCzZy8ozpRlaXJPcu7KXIVGXk9mmwdVhD4kc2Nf1ExvBLFp70Lq3x2HtY3qmV00KTSxRTGi
	name: "ssTakespot",
	components: {},
	data() {
		return {
			stripeLoaded: false,
			paymentSent: false,
			paymentConfirmed: false,
			paymentFailed: false,
			stripe: null,
			elements: null,
			takeError: '',
			text: '',
      photo: null,
			spotTaken: false,
			options: {
				clientSecret: "",
				appearance: {},
			},
			paymentIntentId: "", // Add this to store the payment intent ID
			checkPaymentStatusInterval: null, // For polling
		};
	},
	props: {
    takeSpot: {
      type: Function,
      required: true,
    },
  },
	methods: {
		handleFileUpload(event) {
      this.photo = event.target.files[0];
    },
    async submitForm() {
      const formData = new FormData();
      formData.append('photo', this.photo);
      formData.append('text', this.text);
      try {
        const response = await fetch('takespot.php', {
          method: 'POST',
          body: formData,
        });
        const result = await response.json();
        if (response.ok) {
          if (result.error) {
            this.takeError = result.error;
          } else {
            this.takeError = ''; // Clear error if successful
            console.log(result);
          }
        } else {
          this.takeError = 'An error occurred while submitting the form.1';
        }
      } catch (error) {
        this.takeError = 'An error occurred while submitting the form.1';
        console.error('Error:', error);
      }
    },
		cancelPayment() {
			this.$emit('hideSpot');
		},
		async pay() {
			if (this.stripe && this.options.clientSecret) {
				try {
					const result = await this.stripe.confirmPayment({
						elements: this.elements,
						confirmParams: {
							return_url: "https://google.com",
						},
						redirect: "if_required",
					});
					this.paymentSent = true;
					if (result.error) {
						console.error("Payment failed:", result.error.message);
						this.paymentFailed = true;
					} else {
						console.log("Payment successful!");
						this.paymentSent = true;
						this.paymentIntentId = result.paymentIntent.id; // Store the payment intent ID
						this.startPollingPaymentStatus(); // Start polling for payment status
					}
				} catch (error) {
					console.error("Error during payment:", error);
					this.paymentSent = false;
				}
			} else {
				console.error("Stripe or client secret not loaded");
			}
		},
		startPollingPaymentStatus() {
			this.checkPaymentStatusInterval = setInterval(async () => {
				try {
					const response = await fetch(
						`check_payment_status.php?payment_intent_id=${this.paymentIntentId}`
					);
					const data = await response.json();
					console.log("Payment status:", data.status);
					if (data.status === "succeeded") {
						this.paymentConfirmed = true;
						clearInterval(this.checkPaymentStatusInterval);
					} else if (data.status === "noexist") {
						// Do nothing, havent received webhook yet
					} else {
						// Handle payment failed.
						this.paymentFailed = true;
						clearInterval(this.checkPaymentStatusInterval);
					}
				} catch (error) {
					console.error("Error checking payment status:", error);
				}
			}, 3000); // Poll every 5 seconds
		},
	},
	beforeUnmount() {
		if (this.checkPaymentStatusInterval) {
			clearInterval(this.checkPaymentStatusInterval);
		}
	},
	created() {
		const fetchClientSecret = async () => {
			try {
				const response = await fetch("createIntent.php", {
					method: "GET",
				});
				const result = await response.json();
				this.options.clientSecret = result.client_secret;
				// Got the client secret from backend, proceed
				await loadScript("https://js.stripe.com/v3");
				console.log("Stripe.js loaded");
				/* eslint-disable no-undef */
				this.stripe = Stripe(
					"pk_live_51PBkjy2MsisEK4FeW2PHp9B53EmHxMKyJPZ4ChMsAvuKbUHNlXyOdUwvO6W2HgQEiBh2FdpLRz7z3ayLdDuJZs9x00AY6KuIO2"
				);
				this.elements = this.stripe.elements(this.options);
				this.stripeLoaded = true;
				const paymentElement = this.elements.create("payment");
				paymentElement.mount("#payment-element");
			} catch (error) {
				console.error("Error fetching client secret:", error);
			}
		};
		fetchClientSecret();
	},
};
</script>
<style scoped>
.take-error {
    color: #ff4d4f; /* Red color for error message */
    background-color: #fff0f0; /* Light red background */
    border: 1px solid #ff4d4f; /* Red border */
    padding: 10px; /* Some padding around the error message */
    margin-top: 10px; /* Margin to separate the error message from other elements */
    border-radius: 5px; /* Rounded corners */
    font-size: 14px; /* Font size for the error message */
		margin-bottom: 10px;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height */
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #dadada;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.payment-form {
	border: solid 2px #8a8a8a;
	border-radius: 8px;
	padding: 20px;
}
.paymodal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #dadada;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.take-spot {
	text-align: center;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: solid 1px #aaa;
	border-radius: 12px;
	background-color: #8bd98b;
	width: 60%;
	margin: 0 auto;
}
.form-group {
	margin-bottom: 20px;
}
label {
	display: block;
	margin-bottom: 5px;
}
input[type="file"],
input[type="text"] {
	width: 100%;
	padding: 8px;
	box-sizing: border-box;
}
button {
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
}
@media only screen and (max-width: 768px) {
	.take-spot {
		width: 90%;
		padding: 8px 0;
	}
}
</style>
