<!-- src/views/vsAbout.vue -->
<template>
	<div class="page-container">
		<ssContainer style="margin-top: 30px;">
      <ssMainbox />  
      <ssInfo />
    </ssContainer>
	</div>
</template>

<script>
import ssContainer from "../components/ssContainer.vue";
import ssMainbox from "../components/ssMainbox.vue";
import ssInfo from "../components/ssInfo.vue";
export default {
	name: "ssHome",
	components: {
		ssContainer,
		ssMainbox,
    ssInfo,
	},
};
</script>
