<template>
	<header>
		<div class="header-container">
      <div class="header-image"><img :src="require('../assets/logo.webp')" height="80" width="80" /></div>
		</div>
	</header>
</template>

<style scoped>
header {
	background-color: #2f4f4f;
	box-sizing: border-box;
	color: rgb(255, 255, 255);
	font-family: "Josefin Sans", sans-serif;;
	font-size: 22px;
	line-height: 30.8px;
	position: relative;
	width: 100%;
}
.header-container {
	align-items: center;
	box-sizing: border-box;
	color: rgb(255, 255, 255);
	column-gap: 20px;
	display: flex;
	font-size: 22px;
	justify-content: center;
	padding: 20px;
	line-height: 30.8px;
	margin: 0 auto;
	max-width: 1120px;
	row-gap: 20px;
}
</style>
<script>
export default {
	name: "ssHeader",
}
</script>