<template>
	<div class="wrapper">
		<ssHeader />
		<main style="flex: 1;">
			<slot></slot>
		</main>
    <ssFooter />
	</div>
</template>
<style scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;
}
</style>
<script>
import ssHeader from "./ssHeader.vue";
import ssFooter from "./ssFooter.vue";
export default {
	name: "vsLayout",
	components: {
		ssHeader,
		ssFooter,
	},
};
</script>