<template>
  <div class="main-section">
    <div class="image-container">
      <img :src="currentImage" alt="Your Image Here" height="400" width="400"/>
      <p class="text-line">{{ currentText }}</p>
    </div>
    <header>
      <h3>Be the Star of the Show for Just $5</h3>
    </header>
    <div class="timer" v-if="timer > 0">
      <p>Spot Available In: {{ formattedTime }}</p>
    </div>
    <div class="timer" v-else>
      <p>Spot Available Now!</p>
    </div>
    <button @click="takeTheSpot" :disabled="timer > 0">Take the Spot for $5</button>
  </div>
  <div v-if="takingSpot">
    <ssTakespot @hideSpot="releaseTheSpot" />
  </div>
</template>
<script>
import ssTakespot from "./ssTakespot.vue";
export default {
  name: "ssMainbox",
  data() {
    return {
      currentImage: "", // Remove the placeholder image URL
      currentText: "", // Remove the placeholder text
      timer: 0, // Initialize timer to 0
      intervalId: null,
      takingSpot: false,
    };
  },
  computed: {
    // Calculate minutes from the timer
    minutes() {
      return Math.floor(this.timer / 60);
    },
    // Calculate seconds from the timer
    seconds() {
      return String(this.timer % 60).padStart(2, '0');
    },
    // Format the timer as minutes:seconds
    formattedTime() {
      return `${this.minutes}:${this.seconds}`;
    }
  },
  methods: {
    async fetchCurrentSpot() {
      try {
        const response = await fetch('getCurrentSpot.php');
        const data = await response.json();
        if (data) {
          this.currentImage = data.current_photo;
          this.currentText = data.current_text;
          // Parse the timestamp
          const lastUploadTime = new Date(data.timestamp.replace(' ', 'T') + 'Z');
          const currentTime = new Date();
          const tenMinutesInMs = 10 * 60 * 1000;
          const elapsedTime = currentTime - lastUploadTime;
          const remainingTime = Math.max(tenMinutesInMs - elapsedTime, 0);
          this.timer = Math.floor(remainingTime / 1000);
          this.startTimer(); // Start the timer based on the calculated remaining time
        }
      } catch (error) {
        console.error('Error fetching current spot:', error);
      }
    },
    takeTheSpot() {
      this.takingSpot = true;
    },
    releaseTheSpot() {
      this.takingSpot = false;
    },
    startTimer() {
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer -= 1;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000); // 1 second intervals
    },
  },
  mounted() {
    this.fetchCurrentSpot(); // Fetch the current spot data when the component is mounted
  },
  beforeUnmount() {
    if (this.intervalId) clearInterval(this.intervalId); // Clear the interval when the component is unmounted
  },
  components: {
    ssTakespot,
  }
};
</script>
<style scoped>
.main-section {
  text-align: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
header {
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
}
.image-container {
  margin: 20px 0;
}
img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.text-line {
  font-size: 18px;
  margin-top: 10px;
  font-style: italic;
  color: #555;
}
button {
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: #fff;
  transition: background-color 0.3s ease;
}
button:disabled {
  background-color: grey;
  cursor: not-allowed;
}
button:hover:enabled {
  background-color: #0056b3;
}
.timer {
  margin-top: 20px;
  font-size: 20px;
  color: red;
  font-weight: bold;
}
</style>
